<template>
  <section v-if="reservationInfo && !isVisitor" id="check" :class="useClass">
    <div class="partner_info">
      <div class="reserve_info">
        {{ reservationDateHeaderLabel }}
        {{ reservationDateHeaderSubLabel }}
        {{ reservationCourseHeaderLabel }}
        <p>
          <strong>{{ ccName }}</strong>
        </p>
        <div class="refresh" @click="fetch" />
      </div>
    </div>
    <div class="partner_box">
      <dl class="dl_reserve_list">
        <dt>{{ $t("main.reservationNo") }}</dt>
        <dd>{{ resveNoByKiosk }}</dd>
        <dt>{{ $t("main.reservationState") }}</dt>
        <dd>
          <span :class="['state', reservationStateClass]">
            {{ reservationStateLabel }}
          </span>
        </dd>
        <dt>{{ $t("main.reservationMember") }}</dt>
        <dd>{{ reservationMemberNameAndPhoneNumber }}</dd>
        <dt>{{ $t("main.reservationChannel") }}</dt>
        <dd>{{ reservationChannel }}</dd>
        <dt>{{ $t("main.visitCount") }}</dt>
        <dd>
          {{ $t("main.visitCountSuffix", [visitCount]) }}
          <!-- <input
            v-for="count in [4, 3]"
            :key="count"
            :class="['btn_num_select', visitCount === count && 'active']"
            style="margin-left: 5px"
            type="button"
            :value="`${count}명`"
          /> -->
          <!-- @click="visitCount = count" -->
        </dd>
        <dt v-if="isBooker">
          {{ $t("main.reservationAvailableCancelDateTime") }}
        </dt>
        <dd v-if="isBooker">{{ reservationAvailableCancelDateTime }}</dd>
      </dl>
      <ul class="people_list">
        <li
          v-for="member in reservationCmpnList"
          :key="`member${member.sno}`"
          @click="onClickModifyPartner(member)"
        >
          <span
            :class="[
              'ico',
              reservationSexClass(member.sexCode),
              !isEqualsFirstCompanion && member.reprsntFlag && 'crown',
            ]"
          />
          {{ member.cmpnName }}
          <a
            v-if="
              isBooker &&
              member.resveId &&
              !member.reprsntFlag &&
              !member.isCheckedIn
            "
            class="btn_people"
            data-animation="fade"
            @click.self="onClickDeletePartner(member.sno)"
          />
          <a v-if="member.isCheckedIn" class="ico_checkin" />
        </li>
        <li
          v-for="more in reservationCmpnMoreList"
          :key="`more${more.sno}`"
          @click="onClickAddPartner"
        >
          <a data-animation="fade">
            <span class="ico more" />
          </a>
        </li>
      </ul>
    </div>
    <div class="main_btn_wrap">
      <a
        v-html="goCheckInPageLabel"
        class="btn_basic btn_checkin"
        @click="onClickGoCheckInPage()"
      />
    </div>
    <partner-ok-popup
      v-if="isPartnerOkPopupOpened"
      @submit="onPartnerOkPopupSubmit"
    />
    <partner-register-popup
      v-if="isPartnerRegisterPopupOpened"
      ref="partnerRegisterPopup"
      @close="onPartnerRegisterPopupClose"
      @submit="onPartnerRegisterPopupSubmit"
    />
    <partner-modify-popup
      v-if="isPartnerModifyPopupOpened"
      ref="partnerModifyPopup"
      @close="onPartnerModifyPopupClose"
      @submit="onPartnerModifyPopupSubmit"
    />
    <partner-delete-popup
      v-if="isPartnerDeletePopupOpened"
      ref="partnerDeletePopup"
      @close="onPartnerDeletePopupClose"
      @submit="onPartnerDeletePopupSubmit"
    />
  </section>
</template>

<script>
import checkInMixin from "@/mixins/checkInMixin";
import geolocationMixin from "@/mixins/geolocationMixin";
import ProjectService from "@/service/ProjectService";
import { mapGetters } from "vuex";

export default {
  name: "ReservationStatus",
  mixins: [checkInMixin, geolocationMixin],
  props: {
    useClass: {
      type: [Object, Array, String],
      default: null,
    },
  },
  computed: {
    ...mapGetters(["ccName"]),
    goCheckInPageLabel() {
      return this.isCheckedIn
        ? this.$t("main.checkInStatus")
        : `${this.$t("main.checkIn")}
           <span>|</span>
           ${this.$t("main.issuanceLockerKey")}`;
    },
  },
  methods: {
    async onClickGoCheckInPage(isPassCheckIn = false) {
      if (this.isVisitor) return;
      await this.goCheckInPage(isPassCheckIn);
    },
    async goCheckInPage(isPassCheckIn = false) {
      const date = this.reservationDateHeaderLabel;
      const time = this.reservationDateHeaderSubLabel;
      const course = this.reservationCourseHeaderLabel;
      const count = this.visitCount;
      const query = `?date=${date}&time=${time}&course=${course}&count=${count}`;
      if (!isPassCheckIn && !this.isCheckedIn) {
        const geolocation = await this.getGeolocation();
        if (geolocation) {
          const { tgResveConfirm } = await ProjectService.fetchReservation(
            this.reservationId
          );
          if (tgResveConfirm.cancelDiv === "RAIN") {
            await this.confirm({
              content: this.$t("api.errorMessage.CHECKIN_FAIL_RAIN_ISSUE"),
            });
            return;
          }
          const status = await ProjectService.getEcoFrontCheckInValidation(
            this.reservationId,
            geolocation.longitude,
            geolocation.latitude
          );
          if (status === "OK") {
            await this.$router.push(
              `/reservations/${this.reservationId}/check-in?${query}`
            );
          }
          return;
        } else {
          await this.goSettingGpsGuidePage();
        }
      }
      await this.$router.push(
        `/reservations/${this.reservationId}/check-in-status?${query}`
      );
    },
  },
  /* 뒤로가기 버튼 클릭시 팝업 열린 상태면 팝업만 닫히도록 처리 */
  async beforeRouteLeave(to, from, next) {
    if (this.isPopupOpened) {
      this.closeAllPopup();
      const UUID = Math.round(Math.random() * 999999).toString();
      await this.$router.push(`${this.$route.path}?uuid=${UUID}`);
    }
    next();
  },
};
</script>
