let watchID = null;

export default {
  methods: {
    async getGeolocation() {
      // geolocation 미지원 브라우저 TODO 설정 안내 X. 미지원 기기 메시지 표시
      if (!("geolocation" in navigator)) {
        await this.goSettingGpsGuidePage();
        return false;
      }
      if (watchID) {
        navigator.geolocation.clearWatch(watchID);
        watchID = null;
      }
      // getting geolocation
      let geolocation;
      try {
        geolocation = await new Promise((resolve, reject) => {
          watchID = navigator.geolocation.watchPosition(
            (position) => {
              const { longitude, latitude } = position.coords;
              resolve({ longitude, latitude });
            },
            (error) => {
              reject(error);
            }
          );
        });
        return geolocation;
      } catch (e) {
        await this.goSettingGpsGuidePage();
        return false;
      }
    },
    async goSettingGpsGuidePage() {
      await this.$router.push("/guides/setting-gps");
    },
  },
};
