var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reservationInfo && !_vm.isVisitor)?_c('section',{class:_vm.useClass,attrs:{"id":"check"}},[_c('div',{staticClass:"partner_info"},[_c('div',{staticClass:"reserve_info"},[_vm._v(" "+_vm._s(_vm.reservationDateHeaderLabel)+" "+_vm._s(_vm.reservationDateHeaderSubLabel)+" "+_vm._s(_vm.reservationCourseHeaderLabel)+" "),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.ccName))])]),_c('div',{staticClass:"refresh",on:{"click":_vm.fetch}})])]),_c('div',{staticClass:"partner_box"},[_c('dl',{staticClass:"dl_reserve_list"},[_c('dt',[_vm._v(_vm._s(_vm.$t("main.reservationNo")))]),_c('dd',[_vm._v(_vm._s(_vm.resveNoByKiosk))]),_c('dt',[_vm._v(_vm._s(_vm.$t("main.reservationState")))]),_c('dd',[_c('span',{class:['state', _vm.reservationStateClass]},[_vm._v(" "+_vm._s(_vm.reservationStateLabel)+" ")])]),_c('dt',[_vm._v(_vm._s(_vm.$t("main.reservationMember")))]),_c('dd',[_vm._v(_vm._s(_vm.reservationMemberNameAndPhoneNumber))]),_c('dt',[_vm._v(_vm._s(_vm.$t("main.reservationChannel")))]),_c('dd',[_vm._v(_vm._s(_vm.reservationChannel))]),_c('dt',[_vm._v(_vm._s(_vm.$t("main.visitCount")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$t("main.visitCountSuffix", [_vm.visitCount]))+" ")]),(_vm.isBooker)?_c('dt',[_vm._v(" "+_vm._s(_vm.$t("main.reservationAvailableCancelDateTime"))+" ")]):_vm._e(),(_vm.isBooker)?_c('dd',[_vm._v(_vm._s(_vm.reservationAvailableCancelDateTime))]):_vm._e()]),_c('ul',{staticClass:"people_list"},[_vm._l((_vm.reservationCmpnList),function(member){return _c('li',{key:("member" + (member.sno)),on:{"click":function($event){return _vm.onClickModifyPartner(member)}}},[_c('span',{class:[
            'ico',
            _vm.reservationSexClass(member.sexCode),
            !_vm.isEqualsFirstCompanion && member.reprsntFlag && 'crown' ]}),_vm._v(" "+_vm._s(member.cmpnName)+" "),(
            _vm.isBooker &&
            member.resveId &&
            !member.reprsntFlag &&
            !member.isCheckedIn
          )?_c('a',{staticClass:"btn_people",attrs:{"data-animation":"fade"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onClickDeletePartner(member.sno)}}}):_vm._e(),(member.isCheckedIn)?_c('a',{staticClass:"ico_checkin"}):_vm._e()])}),_vm._l((_vm.reservationCmpnMoreList),function(more){return _c('li',{key:("more" + (more.sno)),on:{"click":_vm.onClickAddPartner}},[_vm._m(0,true)])})],2)]),_c('div',{staticClass:"main_btn_wrap"},[_c('a',{staticClass:"btn_basic btn_checkin",domProps:{"innerHTML":_vm._s(_vm.goCheckInPageLabel)},on:{"click":function($event){return _vm.onClickGoCheckInPage()}}})]),(_vm.isPartnerOkPopupOpened)?_c('partner-ok-popup',{on:{"submit":_vm.onPartnerOkPopupSubmit}}):_vm._e(),(_vm.isPartnerRegisterPopupOpened)?_c('partner-register-popup',{ref:"partnerRegisterPopup",on:{"close":_vm.onPartnerRegisterPopupClose,"submit":_vm.onPartnerRegisterPopupSubmit}}):_vm._e(),(_vm.isPartnerModifyPopupOpened)?_c('partner-modify-popup',{ref:"partnerModifyPopup",on:{"close":_vm.onPartnerModifyPopupClose,"submit":_vm.onPartnerModifyPopupSubmit}}):_vm._e(),(_vm.isPartnerDeletePopupOpened)?_c('partner-delete-popup',{ref:"partnerDeletePopup",on:{"close":_vm.onPartnerDeletePopupClose,"submit":_vm.onPartnerDeletePopupSubmit}}):_vm._e()],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"data-animation":"fade"}},[_c('span',{staticClass:"ico more"})])}]

export { render, staticRenderFns }